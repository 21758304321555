import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

export const AUDIO_CONTEXT = new InjectionToken<AudioContext>('AUDIO_CONTEXT', {
  providedIn: 'root',
  factory: () => new AudioContext(),
});

@Injectable({
  providedIn: 'root',
})
export class ExternalAudioValidationService {
  constructor(@Inject(AUDIO_CONTEXT) private audioContext: AudioContext) {}

  validate(audio: File): Observable<ValidationErrors | null> {
    if (!audio) {
      return new Observable((observer) => {
        observer.next({ invalidContent: true });
        observer.complete();
      });
    }

    return new Observable((observer) => {
      const fileReader = new FileReader();

      fileReader.onload = async () => {
        try {
          await this.audioContext.decodeAudioData(
            fileReader.result as ArrayBuffer
          );
          observer.next(null);
          observer.complete();
        } catch (error) {
          observer.next({ invalidContent: true });
          observer.complete();
        }
      };

      fileReader.onerror = () => {
        observer.next({ readError: true });
        observer.complete();
      };

      fileReader.readAsArrayBuffer(audio);
    });
  }
}
