import { Injectable } from '@angular/core';
import { CognitoAuthService } from '@techspert-io/auth';
import { ExpertFile } from '@techspert-io/expert-files';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
  BehaviorSubject,
  Observable,
  filter,
  finalize,
  takeUntil,
  tap,
} from 'rxjs';
import {
  Add,
  Delete,
  ExpertFilesStateService,
  UpdateProgress,
} from '../../../shared/services/filestore-state.service';
import { FileStoreService } from '../../../shared/services/filestore.service';
import { IRespondentFile } from '../../data-capture/models/data-capture.models';

interface ISelectedFile {
  transcriptFile: ExpertFile;
  audioFile?: ExpertFile;
  closestTimestamp?: string;
}

@Injectable()
export class AssistantFilesService {
  loadingFiles$ = this.filesState.state$;
  selectedfile$ = new BehaviorSubject<ISelectedFile>(null);

  downloadedFiles: Record<string, boolean> = {};
  transcriptCache: Record<string, string> = {};
  videoCache: Record<string, Blob> = {};

  constructor(
    private fileStore: FileStoreService,
    private filesState: ExpertFilesStateService,
    private gaService: GoogleAnalyticsService,
    private cognitoAuthService: CognitoAuthService
  ) {}

  getAssistantTranscriptFileContents(
    file: IRespondentFile
  ): Observable<string> {
    if (this.transcriptCache[file.fileKey]) {
      return new Observable((observer) => {
        observer.next(this.transcriptCache[file.fileKey]);
        observer.complete();
      });
    }

    return this.fileStore
      .getFileContent(file.fileKey)
      .pipe(tap((data) => (this.transcriptCache[file.fileKey] = data)));
  }

  getAssistantAudioFileContents(file: IRespondentFile): Observable<Blob> {
    if (this.videoCache[file.fileKey]) {
      return new Observable((observer) => {
        observer.next(this.videoCache[file.fileKey]);
        observer.complete();
      });
    }

    return this.fileStore
      .getExpertAudioFileContents(file.fileKey)
      .pipe(tap((data) => (this.videoCache[file.fileKey] = data)));
  }

  downloadFile(file: IRespondentFile): void {
    this.downloadFileImpl(file);
  }

  cancelDownload(fileName: string): void {
    this.filesState.dispatch(new Delete(fileName));
  }

  setSelectedFile(file: ISelectedFile): void {
    this.selectedfile$.next(file);
  }

  private downloadFileImpl(file: IRespondentFile): void {
    this.filesState.dispatch(new Add(file.fileKey));
    this.fileStore
      .downloadExpertFile(
        file.fileKey,
        `${file.fileName}.${file.fileExtension}`
      )
      .pipe(
        takeUntil(this.loadingFiles$.pipe(filter((d) => !d[file.fileKey]))),
        tap((event) =>
          this.filesState.dispatch(
            new UpdateProgress(file.fileKey, event.percentageComplete)
          )
        ),
        finalize(() => {
          this.downloadedFiles[file.fileKey] = true;
          this.filesState.dispatch(new Delete(file.fileKey));

          this.gaService.gtag('event', 'download', {
            event_category: 'assistant_files',
            event_label: file.type,
            // TODO [ENG-2028] - handling for external respondent files
            value: file.fileId || 'no-id',
            dimension1: this.cognitoAuthService.loggedInUser.id,
          });
        })
      )
      .subscribe();
  }
}
