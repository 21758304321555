import {
  HttpBackend,
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, filter, map, of } from 'rxjs';
import { IFileProgressEvent } from '../../../shared/services/filestore.service';
import {
  IDataCaptureSegment,
  IDataCaptureSheet,
  IDataCaptureSheetBase,
  IExternalConnectionUpload,
  IRespondent,
  IRespondentAnswer,
} from '../models/data-capture.models';

interface IUploadExternalConnectionFilesPayload {
  externalRespondentId: string;
  opportunitySegmentId: string;
  audioExtension?: string;
}

interface IPutFile {
  fileKey: string;
  uploadUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataCaptureService {
  private readonly baseUrl = '/insight-extraction';

  private httpNoAuth = new HttpClient(this.httpBackend);

  constructor(private http: HttpClient, private httpBackend: HttpBackend) {}

  querySheetsByOpportunityId(opportunityId: string) {
    return this.http.get<IDataCaptureSheet[]>(
      `${this.baseUrl}/data-capture-sheet?opportunityId=${opportunityId}`
    );
  }

  createSheet(payload: Omit<IDataCaptureSheetBase, 'data_capture_sheet_id'>) {
    return this.http.post<IDataCaptureSheet>(
      `${this.baseUrl}/data-capture-sheet`,
      payload
    );
  }

  editSheet(
    payload: Partial<IDataCaptureSheet> & { data_capture_sheet_id: string }
  ) {
    return this.http.patch<IDataCaptureSheet>(
      `${this.baseUrl}/data-capture-sheet/${payload.data_capture_sheet_id}`,
      payload
    );
  }

  deleteSheet(dataCaptureId: string) {
    return this.http.delete<undefined>(
      `${this.baseUrl}/data-capture-sheet/${dataCaptureId}`
    );
  }

  getRespodentsByDataCaptureSheetId(dataCaptureSheetId: string) {
    return this.http.get<IRespondent[]>(
      `${this.baseUrl}/data-capture-sheet/respondents?dataCaptureSheetId=${dataCaptureSheetId}`
    );
  }

  getAvailableSegments(opportunityId: string) {
    return this.http.get<IDataCaptureSegment[]>(
      `${this.baseUrl}/available-segments?opportunityId=${opportunityId}`
    );
  }

  updateRespondentAnswer(
    respondentAnswer: (Partial<IRespondentAnswer> & {
      respondent_id: string;
      question_id: string;
    })[]
  ) {
    return this.http.patch<IRespondentAnswer[]>(
      `${this.baseUrl}/respondent-answer`,
      respondentAnswer
    );
  }

  uploadExternalConnectionFile(payload: IUploadExternalConnectionFilesPayload) {
    return this.http.get<{
      audio: IPutFile;
      transcript: IPutFile;
    }>(
      `${this.baseUrl}/external-connections/upload?externalRespondentId=${payload.externalRespondentId}&opportunitySegmentId=${payload.opportunitySegmentId}&audioExtension=${payload.audioExtension}`
    );
  }

  createExternalConnection(payload: IExternalConnectionUpload) {
    return this.http.put<string>(
      `${this.baseUrl}/external-connections/create`,
      payload
    );
  }

  deleteExternalConnection(externalConnectionId: string) {
    return this.http.delete<undefined>(
      `${this.baseUrl}/external-connections/${externalConnectionId}`
    );
  }

  uploadFile(file: File, uploadUrl: string): Observable<IFileProgressEvent> {
    const req = new HttpRequest('PUT', uploadUrl, file, {
      reportProgress: true,
    });
    return this.httpNoAuth.request(req).pipe(
      map((event) => this.getProgressEvent(event, file.name)),
      filter((event) => event.status === 'complete'),
      catchError(() => this.handleError(file.name))
    );
  }

  private getProgressEvent(
    event: HttpEvent<unknown>,
    fileName: string
  ): IFileProgressEvent {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          fileName,
          percentageComplete: 0,
          status: 'started',
        };

      case HttpEventType.DownloadProgress:
      case HttpEventType.UploadProgress:
        return {
          fileName,
          percentageComplete: Math.round((100 * event.loaded) / event.total),
          status: 'ongoing',
        };

      case HttpEventType.Response:
        return {
          fileName,
          percentageComplete: 100,
          status: 'complete',
        };
    }
  }

  private handleError(fileName: string): Observable<IFileProgressEvent> {
    return of({
      fileName,
      percentageComplete: 0,
      status: 'failed',
    });
  }
}
