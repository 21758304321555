import {
  Component,
  Inject,
  InjectionToken,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AssistantFilesService,
  AssistantQuotesService,
  TranscriptChunkingService,
} from '@techspert-io/insight-assistant';
import * as Excel from 'exceljs';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  combineLatest,
  filter,
  finalize,
  map,
  of,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { WorksheetService } from '../../../../public/opportunity/services/worksheet.service';
import {
  IDataCaptureSegment,
  IDataCaptureSheet,
  IRespondent,
  IRespondentAnswerReference,
  IRespondentFile,
  RespondentSource,
} from '../../models/data-capture.models';
import { DataCaptureService } from '../../services/data-capture.service';
import { AddRespondentComponent } from '../add-respondent/add-respondent.component';
import { DeleteSheetConfirmationComponent } from '../delete-sheet-confirmation/delete-sheet-confirmation.component';
import { EditSheetComponent } from '../edit-sheet/edit-sheet.component';

interface IDisplaySheet extends IDataCaptureSheet {
  selected?: boolean;
  editing?: boolean;
}

interface ITranscriptChunk {
  /** expertId or externalRespondentId */
  respondentId: string;
  start: string;
  end: string;
  startNum: number;
  endNum: number;
  speaker: string;
  content: string;
  highlighted: boolean;
  savedQuoteId?: string;
  loading?: boolean;
}

type OpenCitation = {
  respondent: IRespondent;
  citation: IRespondentAnswerReference;
};

export const WORKBOOK = new InjectionToken<Excel.Workbook>('WORKBOOK');

@Component({
  selector: 'app-data-capture',
  templateUrl: './data-capture.component.html',
  styleUrls: ['./data-capture.component.scss'],
  providers: [
    {
      provide: WORKBOOK,
      useValue: new Excel.Workbook(),
    },
  ],
})
export class DataCaptureComponent implements OnInit {
  @Input() opportunityId: string;

  availableSheets: IDisplaySheet[] = [];

  selectedSheet: IDisplaySheet;

  segments: IDataCaptureSegment[] = [];

  loading = true;

  downloadingCsv = false;
  deletingSheet = false;

  audioStartTime = 0;
  transcriptFile: IRespondentFile;
  audioData: string;
  autoScroll = true;
  transcriptChunks: ITranscriptChunk[] = [];

  viewingCitation = false;
  loadingCitation = false;

  constructor(
    private dialog: MatDialog,
    private dataCaptureService: DataCaptureService,
    private worksheetService: WorksheetService,
    private toastService: ToastrService,
    private assistantFilesService: AssistantFilesService,
    private assistantQuotesService: AssistantQuotesService,
    private transcriptChunkingService: TranscriptChunkingService,
    @Inject(WORKBOOK) private workbook: Excel.Workbook
  ) {}

  ngOnInit() {
    this.refreshDataCapture().subscribe();
  }

  downloadCsv(sheet?: IDataCaptureSheet) {
    if (!this.availableSheets.length) {
      this.toastService.warning('Set up your first sheet', 'No data to export');
      return;
    }

    this.workbook.eachSheet((worksheet) => {
      this.workbook.removeWorksheet(worksheet.name);
    });

    if (sheet) {
      this.dataCaptureService
        .getRespodentsByDataCaptureSheetId(sheet.data_capture_sheet_id)
        .pipe(
          map((respondents) =>
            this.formatWorkbook(
              this.workbook.addWorksheet(sheet.name, {
                properties: { defaultColWidth: 45 },
              }),
              sheet,
              respondents
            )
          ),
          switchMap(() =>
            this.worksheetService.save(this.workbook, `${sheet.name}-export`)
          ),
          catchError((err) => {
            this.toastService.error(
              'Please try again',
              'Error exporting sheet'
            );
            return err;
          })
        )
        .subscribe();
    } else {
      this.downloadingCsv = true;

      combineLatest(
        this.availableSheets.map((s) =>
          this.dataCaptureService
            .getRespodentsByDataCaptureSheetId(s.data_capture_sheet_id)
            .pipe(
              tap((respondents) =>
                this.formatWorkbook(
                  this.workbook.addWorksheet(s.name, {
                    properties: { defaultColWidth: 45 },
                  }),
                  s,
                  respondents
                )
              )
            )
        )
      )
        .pipe(
          switchMap(() =>
            this.worksheetService.save(this.workbook, 'data-capture-export')
          ),
          catchError((err) => {
            this.toastService.error(
              'Please try again',
              'Error exporting sheets'
            );
            return err;
          }),
          finalize(() => (this.downloadingCsv = false))
        )
        .subscribe();
    }
  }

  addRespondent() {
    this.dialog
      .open<AddRespondentComponent, string, boolean>(AddRespondentComponent, {
        width: '600px',
        minHeight: '500px',
        autoFocus: false,
        data: this.opportunityId,
      })
      .afterClosed()
      .pipe(
        takeWhile((res) => !!res),
        tap(() => (this.loading = true)),
        switchMap(() => this.refreshDataCapture())
      )
      .subscribe();
  }

  edit(sheet: IDataCaptureSheet) {
    this.dialog
      .open<EditSheetComponent, IDataCaptureSheet, IDataCaptureSheet>(
        EditSheetComponent,
        {
          width: '1000px',
          height: '600px',
          autoFocus: false,
          data: sheet,
        }
      )
      .afterClosed()
      .pipe(
        takeWhile((res) => !!res),
        tap(() => {
          this.loading = true;
        }),
        switchMap((res) => this.dataCaptureService.editSheet(res)),
        tap((res) => {
          this.availableSheets = this.availableSheets.map((s) =>
            s.data_capture_sheet_id === sheet.data_capture_sheet_id
              ? { ...sheet, ...res }
              : s
          );
          this.selectedSheet = this.availableSheets.find(
            (s) => s.data_capture_sheet_id === sheet.data_capture_sheet_id
          );

          const removedSegmentIds = sheet.opportunity_segment_ids.filter(
            (id) => !res.opportunity_segment_ids.includes(id)
          );

          const addedSegmentIds = res.opportunity_segment_ids.filter(
            (id) => !sheet.opportunity_segment_ids.includes(id)
          );

          this.segments = this.segments.map((s) =>
            removedSegmentIds.includes(s.segmentId)
              ? { ...s, occupied: false }
              : addedSegmentIds.includes(s.segmentId)
              ? { ...s, occupied: true }
              : s
          );
        }),
        switchMap((res) =>
          res.answer_format !== sheet.answer_format
            ? this.refreshDataCapture().pipe(
                tap(() => {
                  this.selectedSheet = this.availableSheets.find(
                    (s) => s.data_capture_sheet_id === res.data_capture_sheet_id
                  );
                })
              )
            : of(null)
        ),
        catchError((err) => {
          this.toastService.error('Please try again', 'Error editing sheet');
          return err;
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  deleteSheet(sheet: IDataCaptureSheet) {
    this.dialog
      .open<DeleteSheetConfirmationComponent, IDataCaptureSheet, boolean>(
        DeleteSheetConfirmationComponent,
        {
          width: '500px',
          autoFocus: false,
          data: sheet,
        }
      )
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        tap(() => {
          this.deletingSheet = true;
          this.viewingCitation = false;
        }),
        switchMap(() =>
          this.dataCaptureService.deleteSheet(sheet.data_capture_sheet_id)
        ),
        tap(() => {
          this.availableSheets = this.availableSheets.filter(
            (s) => s.data_capture_sheet_id !== sheet.data_capture_sheet_id
          );
          this.selectedSheet =
            this.selectedSheet.data_capture_sheet_id ===
            sheet.data_capture_sheet_id
              ? this.availableSheets.find(Boolean)
              : this.selectedSheet;
          this.segments = this.segments.map((s) =>
            sheet.opportunity_segment_ids.includes(s.segmentId)
              ? { ...s, occupied: false }
              : s
          );
        }),
        catchError((err) => {
          this.toastService.error('Please try again', 'Error removing sheet');
          return err;
        }),
        finalize(() => (this.deletingSheet = false))
      )
      .subscribe();
  }

  selectSheet(sheet: IDataCaptureSheet) {
    this.selectedSheet = sheet;
  }

  newSheet() {
    const unassignedSegments = this.segments.filter((s) => !s.occupied);

    if (!unassignedSegments.length) {
      this.toastService.warning(
        'No outstanding segments available',
        'Edit one of your existing sheets'
      );
      return;
    }

    this.selectedSheet = undefined;
  }

  createdSheet(sheet: IDataCaptureSheet) {
    this.availableSheets = [...this.availableSheets, sheet];
    this.selectedSheet = sheet;
    this.segments = this.segments.map((s) =>
      sheet.opportunity_segment_ids.includes(s.segmentId)
        ? { ...s, occupied: true }
        : s
    );
  }

  cancelSetup() {
    this.selectedSheet = this.availableSheets.find(Boolean);
  }

  openCitation(citation: OpenCitation) {
    this.viewingCitation = true;
    this.loadingCitation = true;
    this.loadResources(citation.respondent, citation.citation);
  }

  closeCitation() {
    this.viewingCitation = false;
  }

  updateQuote(chunk: ITranscriptChunk) {
    if (chunk.loading) {
      return;
    }

    chunk.loading = true;

    if (chunk.savedQuoteId) {
      this.assistantQuotesService
        .deleteQuote(chunk.savedQuoteId)
        .subscribe(() => {
          chunk.savedQuoteId = null;
          chunk.loading = false;
        });
    } else {
      this.assistantQuotesService
        .saveQuote({
          transcriptFileId: this.transcriptFile.fileId || chunk.respondentId,
          expertId: chunk.respondentId,
          startTime: chunk.startNum,
          endTime: chunk.endNum,
          quote: chunk.content,
        })
        .subscribe((quote) => {
          chunk.savedQuoteId = quote.savedQuoteId;
          chunk.loading = false;
        });
    }
  }

  updateTime(event: Event) {
    const target = event.target as HTMLAudioElement;
    this.updateAndScroll(target.currentTime);
  }

  setStartTime(event: Event): void {
    const audio = event.target as HTMLAudioElement;
    audio.currentTime = this.audioStartTime;
  }

  private refreshDataCapture() {
    return combineLatest([
      this.dataCaptureService.querySheetsByOpportunityId(this.opportunityId),
      this.dataCaptureService.getAvailableSegments(this.opportunityId),
    ]).pipe(
      tap(([sheets, segments]) => {
        this.availableSheets = sheets.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
        this.selectedSheet = this.availableSheets.find(Boolean);

        this.segments = segments;
        this.loading = false;
      })
    );
  }

  private loadResources(
    respondent: IRespondent,
    citation: IRespondentAnswerReference
  ) {
    const transcriptFile = respondent.processedTranscript;
    const audioFile = respondent.audio;

    combineLatest([
      this.assistantFilesService.getAssistantTranscriptFileContents(
        transcriptFile
      ),
      audioFile
        ? this.assistantFilesService.getAssistantAudioFileContents(audioFile)
        : of(undefined),
      this.assistantQuotesService.getQuotesByTranscriptFileId(
        transcriptFile.fileId || respondent.respondentId
      ),
    ]).subscribe(([content, audio, quotes]) => {
      this.transcriptChunks = this.transcriptChunkingService
        .createChunks(content, quotes)
        .map((chunk) => ({
          ...chunk,
          respondentId:
            respondent.type === RespondentSource.Techspert
              ? respondent.source
              : respondent.respondentId,
        }));

      this.transcriptFile = transcriptFile;

      if (audio) {
        this.audioData = URL.createObjectURL(audio);
      }

      this.audioStartTime = citation ? citation.timestampStart / 1000 : 0;

      this.updateAndScroll(this.audioStartTime);

      this.loadingCitation = false;
    });
  }

  private updateAndScroll(time: number) {
    const currentHighlightedPosition = this.transcriptChunks.findIndex(
      (chunk) => chunk.highlighted
    );

    const newHighlightedPosition = this.transcriptChunks.findIndex(
      (chunk) => chunk.startNum <= time && chunk.endNum >= time
    );

    if (currentHighlightedPosition !== newHighlightedPosition) {
      this.transcriptChunks = this.transcriptChunks.map((chunk) => ({
        ...chunk,
        highlighted: chunk.startNum <= time && chunk.endNum >= time,
      }));
    }

    if (this.autoScroll) {
      setTimeout(() => {
        const element = document.querySelector('.highlight');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }
      });
    }

    if (!time) {
      const element = document.querySelector('.transcript-content');
      if (element) {
        element.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }

  private formatWorkbook(
    worksheet: Excel.Worksheet,
    sheet: IDataCaptureSheet,
    respondents: IRespondent[]
  ) {
    const groupedThemes = this.groupQuestionsByTheme(sheet);

    const additionalColumns = [
      sheet.additional_export_headers.geography ? 'Geography' : '',
      sheet.additional_export_headers.profile_type ? 'Profile type' : '',
      sheet.additional_export_headers.date_and_time_of_call
        ? 'Date and time of call'
        : '',
      sheet.additional_export_headers.expert_bio ? 'Expert biography' : '',
    ].filter(Boolean);

    let startCol = 2 + additionalColumns.length;
    groupedThemes.forEach((theme, i) => {
      const questionCount = Object.keys(theme.questions).length;
      const endCol = startCol + questionCount - 1;

      // Merge cells for the theme header
      worksheet.mergeCells(1, startCol, 1, endCol);
      const themeCell = worksheet.getCell(1, startCol);
      themeCell.value = theme.theme;
      themeCell.alignment = { horizontal: 'center', vertical: 'middle' };
      themeCell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      themeCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: i % 2 ? '49E5FD' : '1E2B4E' },
      };

      startCol = endCol + 1;
    });

    worksheet.addRow(
      [
        'Respondent',
        ...additionalColumns,
        ...groupedThemes.flatMap((theme) =>
          Object.values(theme.questions).map((qDetails) => qDetails.question)
        ),
      ].flat()
    );

    worksheet.getRow(2).eachCell((cell, i) => {
      cell.alignment = {
        wrapText: true,
        horizontal: 'center',
        vertical: 'middle',
      };
      cell.border = {
        bottom: { style: 'thin' },
      };
      cell.font = { bold: true };

      if (i > 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F2F2F2' },
        };
      }
    });

    worksheet.columns = [
      { header: '', key: '' },
      ...additionalColumns.map((col) => ({
        header: '',
        key: col,
      })),
      ...groupedThemes.map((theme) => ({
        header: theme.theme,
        key: theme.theme,
      })),
    ];

    for (const respondent of respondents) {
      const mappedAnswers = groupedThemes.flatMap((theme) =>
        Object.values(theme.questions).map(
          (qDetails) => respondent.answers[qDetails.question_id]?.answer || ''
        )
      );

      const additionalColumns = [
        sheet.additional_export_headers.geography
          ? respondent.geography
          : false,
        sheet.additional_export_headers.profile_type
          ? respondent.segment
          : false,
        sheet.additional_export_headers.date_and_time_of_call
          ? this.formatDate(respondent.callDate)
          : false,
        sheet.additional_export_headers.expert_bio
          ? this.stripHTML(respondent.bio)
          : false,
      ].filter((r) => r !== false);

      const newRow = worksheet.addRow(
        [
          respondent.firstName + ' ' + respondent.lastName,
          ...additionalColumns,
          ...mappedAnswers,
        ].flat()
      );

      newRow.getCell(1).font = { bold: true };

      newRow.eachCell((cell) => {
        cell.alignment = {
          wrapText: true,
          vertical: 'middle',
          horizontal: 'center',
        };
      });
    }
  }

  private formatDate(date: string) {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.getFullYear();
    const hours = d.getHours();
    const minutes = d.getMinutes();

    const ampm = hours >= 12 ? 'pm' : 'am';

    return `${day} ${month} ${year}, ${hours}:${minutes
      .toString()
      .padStart(2, '0')} ${ampm}`;
  }

  private groupQuestionsByTheme(sheet: IDataCaptureSheet) {
    const themes = sheet.discussion_guide.map((question) => question.theme);

    return [...new Set(themes)].map((theme) => ({
      theme,
      questions: sheet.discussion_guide.filter(
        (question) => question.theme === theme
      ),
    }));
  }

  private stripHTML(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }
}
