import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { catchError, tap } from 'rxjs';
import {
  IDataCaptureSegment,
  IDataCaptureSheet,
} from '../../models/data-capture.models';
import { DataCaptureService } from '../../services/data-capture.service';
import {
  FormGroupQuestion,
  FormGroupTheme,
} from '../re-orderable-theme/re-orderable-theme.component';

@Component({
  selector: 'app-sheet-get-started',
  templateUrl: './sheet-get-started.component.html',
  styleUrls: ['./sheet-get-started.component.scss'],
})
export class SheetGetStartedComponent implements OnInit {
  @Input() opportunityId: string;
  @Input() step = 0;
  @Input() initialSheet = false;
  @Output() createdSheet = new EventEmitter<IDataCaptureSheet>();
  @Output() cancel = new EventEmitter<void>();

  themesFormSubmitted = false;

  collapsedPreview = false;

  gettingStartedFormGroup = new FormGroup({
    firstSheetName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(32),
      this.validateName(),
    ]),
  });

  segmentsFormGroup = new FormGroup({
    segments: new FormControl([], [Validators.required, Validators.min(1)]),
  });

  themesFormGroup = new FormGroup({
    reorderablePanel: new FormGroup({
      themes: new FormArray<FormGroupTheme>(
        [
          new FormGroup({
            theme: new FormControl<string>('Interview section'),
            questions: new FormArray<FormGroupQuestion>([
              new FormGroup({
                question_id: new FormControl<string>(this.generateUniqueId()),
                question: new FormControl<string>('Question 1'),
                theme: new FormControl<string>('Interview section'),
                label: new FormControl<string>(''),
              }),
              new FormGroup({
                question_id: new FormControl<string>(this.generateUniqueId()),
                question: new FormControl<string>('Question 2'),
                theme: new FormControl<string>('Interview section'),
                label: new FormControl<string>(''),
              }),
            ]),
          }),
        ],
        [Validators.required, Validators.min(1), this.validateThemes()]
      ),
    }),
  });

  answerConfigFormGroup = new FormGroup({
    answerType: new FormControl<'summarised' | 'verbatim'>(
      'summarised',
      Validators.required
    ),
  });

  additionalFieldsFormGroup = new FormGroup({
    expertName: new FormControl(true),
    dateAndTimeOfCall: new FormControl(true),
    geography: new FormControl(true),
    profileType: new FormControl(true),
    interviewerName: new FormControl(false),
    notetakerName: new FormControl(false),
    recruitmentSource: new FormControl(false),
    expertBio: new FormControl(false),
  });

  segments: IDataCaptureSegment[] = [];

  loading = true;

  creatingSheet = false;

  get firstSheetName(): FormControl<string> {
    return this.gettingStartedFormGroup.controls.firstSheetName;
  }

  get themesControl(): FormArray<FormGroupTheme> {
    return this.themesFormGroup.controls.reorderablePanel.controls.themes;
  }

  get sheetHeaders(): IDataCaptureSheet['additional_export_headers'] {
    const additionalFields = this.additionalFieldsFormGroup.value;

    return {
      expert_name: additionalFields.expertName,
      expert_bio: additionalFields.expertBio,
      date_and_time_of_call: additionalFields.dateAndTimeOfCall,
      geography: additionalFields.geography,
      profile_type: additionalFields.profileType,
      interviewer_name: additionalFields.interviewerName,
      notetaker_name: additionalFields.notetakerName,
    };
  }

  get selectedSegments() {
    return this.segments.filter((segment) =>
      this.segmentsFormGroup.controls.segments.value.includes(segment.segmentId)
    );
  }

  get themes() {
    return this.themesFormGroup.controls.reorderablePanel.controls.themes.value.flatMap(
      (theme) => theme.questions.map((q) => ({ ...q, theme: theme.theme }))
    );
  }

  constructor(
    private dataCaptureService: DataCaptureService,
    private toastService: ToastrService
  ) {}

  ngOnInit() {
    this.dataCaptureService
      .getAvailableSegments(this.opportunityId)
      .subscribe((res) => {
        this.segments = res;
        this.loading = false;
      });
  }

  getStarted() {
    this.step = 1;
  }

  complete() {
    const { firstSheetName } = this.gettingStartedFormGroup.value;
    const { segments } = this.segmentsFormGroup.value;

    const themes =
      this.themesFormGroup.controls.reorderablePanel.controls.themes;

    const additionalFields = this.additionalFieldsFormGroup.value;

    this.creatingSheet = true;

    this.dataCaptureService
      .createSheet({
        name: firstSheetName,
        opportunity_id: this.opportunityId,
        opportunity_segment_ids: segments,
        discussion_guide: themes.value.flatMap((theme) =>
          theme.questions.map((question) => ({
            question_id: question.question_id,
            question: question.question,
            theme: theme.theme,
            label: question.label,
          }))
        ),
        answer_format: this.answerConfigFormGroup.value.answerType,
        additional_export_headers: {
          expert_name: additionalFields.expertName,
          expert_bio: additionalFields.expertBio,
          date_and_time_of_call: additionalFields.dateAndTimeOfCall,
          geography: additionalFields.geography,
          profile_type: additionalFields.profileType,
          interviewer_name: additionalFields.interviewerName,
          notetaker_name: additionalFields.notetakerName,
        },
      })
      .pipe(
        tap((res) => {
          this.createdSheet.emit(res);
        }),
        catchError((err) => {
          this.creatingSheet = false;

          this.toastService.error('Please try again', 'Error creating sheet');

          return err;
        })
      )
      .subscribe();
  }

  cancelCreation() {
    if (this.initialSheet) {
      this.step = 0;
    }

    this.cancel.emit();
  }

  onThemesFormSubmit() {
    this.themesFormSubmitted = true;
  }

  setCollapsedState() {
    this.collapsedPreview = !this.collapsedPreview;
  }

  private generateUniqueId() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  private validateName(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors => {
      if (control.value.match(/[?:\\\/[\]*]/g)) {
        return {
          invalidName: true,
        };
      }

      return null;
    };
  }

  private validateThemes(): ValidatorFn {
    return (control: FormArray<FormGroupTheme>): ValidationErrors => {
      if (!control.value.length) {
        return {
          noThemes: true,
        };
      }

      if (control.value.some((theme) => !theme.theme)) {
        return {
          noThemeName: true,
        };
      }

      if (control.value.some((theme) => !theme.questions.length)) {
        return {
          noQuestions: true,
        };
      }

      if (
        control.value.some((theme) =>
          theme.questions.some((question) => !question.question)
        )
      ) {
        return {
          noQuestionName: true,
        };
      }

      const themeNames = control.value.map((theme) => theme.theme);
      const uniqueThemeNames = [...new Set(themeNames)];

      if (themeNames.length !== uniqueThemeNames.length) {
        return { duplicateThemeNames: true };
      }

      if (
        control.value.some((theme) => {
          const questionNames = theme.questions.map((q) => q.question);
          const uniqueQuestionNames = [...new Set(questionNames)];

          return questionNames.length !== uniqueQuestionNames.length;
        })
      ) {
        return { duplicateQuestionNames: true };
      }

      return null;
    };
  }
}
