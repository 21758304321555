import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-verbatim-summarised',
  template: `<div class="container">
    <p>
      Below is an example of how an expert's answer will be displayed, based on
      the selected format.
    </p>

    <div class="output-grid">
      <div class="output-box">
        <b>Summarized</b>
        <p class="output-text">
          The percentage of patients experiencing unmet needs in terms of
          treatment access and affordability ranges from 15% to 25%.
        </p>
      </div>

      <div class="output-box">
        <b>Verbatim</b>
        <p class="output-text">
          So, our analysis indicates that the percentage of patients
          experiencing unmet needs in terms of treatment access and
          affordability ranges range from 15% to 25%, where there is undoubtedly
          a need for improved access to treatments, overstating the extent of
          unmet need could lead...
        </p>
      </div>
    </div>
  </div>`,
  styleUrls: ['./skeleton-verbatim-summarised.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonVerbatimSummarisedComponent {}
