import {
  IRespondent,
  IRespondentFile,
  RespondentSource,
} from '../models/data-capture.models';

export const baseRespondentFileMock: IRespondentFile = {
  fileName: 'fileName',
  fileExtension: 'txt',
  fileKey: 'fileName.txt',
  type: 'manual',
};

export const respondentsMock: IRespondent[] = [
  {
    respondentId: '1',
    firstName: 'John',
    lastName: 'Doe',
    geography: 'US',
    segment: 'Nurse',
    opportunitySegmentId: '1',
    callDate: '2025-01-13T00:00:00.000Z',
    bio: '',
    type: RespondentSource.Techspert,
    source: 'expertId',
    answers: {},
    files: [],
  },
  {
    respondentId: '2',
    firstName: 'Jane',
    lastName: 'Doe',
    geography: 'US',
    segment: 'Nurse',
    opportunitySegmentId: '1',
    callDate: '2025-01-13T00:00:00.000Z',
    bio: '',
    type: RespondentSource.Techspert,
    source: 'expertId',
    answers: {},
    files: [],
  },
];
