import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  AssistantQuotesService,
  ISavedQuote,
} from '@techspert-io/insight-assistant';
import { EMPTY, Observable, combineLatest, map, tap } from 'rxjs';

@Component({
  selector: 'app-expert-quotes',
  template: `<div
    class="flex flex-col gap-4"
    *ngIf="savedQuotes$ | async as savedQuotes"
  >
    <div>
      <table mat-table [dataSource]="savedQuotes">
        <ng-container matColumnDef="quote">
          <th mat-header-cell *matHeaderCellDef>Quote</th>
          <td mat-cell *matCellDef="let element" style="padding: 8px 12px">
            {{ element.quote }}
          </td>
        </ng-container>

        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef [style.width]="'150px'">
            Timecode
          </th>
          <td mat-cell *matCellDef="let element" style="padding: 8px 12px">
            <span *ngIf="!showEchoAsk">
              {{ element.startTime | quoteTimestamp }}
            </span>
            <a
              *ngIf="showEchoAsk"
              style="cursor: pointer; text-decoration: underline; color: blue;"
              [routerLink]="[]"
              [queryParams]="{
                tab: 'echoAsk',
                resource: 'expert',
                resourceId: expertId,
                fileId: element.transcriptFileId,
                startTime: element.startTime
              }"
              >{{ element.startTime | quoteTimestamp }}</a
            >
          </td>
        </ng-container>

        <tr *matNoDataRow>
          <td *ngIf="!showEchoAsk">
            No expert quotes currently saved. Go to AI Assistant to favorite any
            transcript citation and it will display here for future reference.
          </td>
          <td
            *ngIf="showEchoAsk"
            [attr.colspan]="displayedColumns.length"
            style="padding: 8px 12px"
          >
            No expert quotes currently saved. Go to
            <a
              style="cursor: pointer; text-decoration: underline; color: blue;"
              [routerLink]="[]"
              [queryParams]="{
                tab: 'echoAsk',
                resource: 'expert',
                resourceId: expertId
              }"
              >AI Assistant</a
            >
            to favorite any transcript citation and it will display here for
            future reference.
          </td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
    </div>

    <button
      *ngIf="showEchoAsk"
      class="nav-button"
      mat-flat-button
      color="primary"
      [routerLink]="[]"
      [queryParams]="{
        tab: 'echoAsk',
        resource: 'expert',
        resourceId: expertId
      }"
    >
      {{ savedQuotes.data.length ? 'Generate more quotes' : 'Generate quotes' }}
    </button>
  </div>`,
  styles: [
    `
      .nav-button {
        margin-left: auto;
        margin-right: auto;
      }
    `,
  ],
})
export class ExpertQuotesComponent implements OnInit {
  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @Input() expertId: string;
  @Input() showEchoAsk = true;

  displayedColumns: string[] = ['quote', 'startTime'];

  savedQuotes$: Observable<MatTableDataSource<ISavedQuote>> = EMPTY;

  constructor(private assistantQuotesService: AssistantQuotesService) {}

  ngOnInit() {
    this.savedQuotes$ = combineLatest([
      this.assistantQuotesService.allQuotes$,
      this.assistantQuotesService.getQuotesByExpertId(this.expertId),
    ]).pipe(
      map(([data]) =>
        (data[this.expertId] || []).sort((a, b) => a.startTime - b.startTime)
      ),
      map((data) => new MatTableDataSource(data)),
      tap((dataSource) => {
        dataSource.paginator = this.matPaginator;
      })
    );
  }
}
