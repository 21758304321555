<div *ngIf="uploading" class="loading-container flex flex-col items-center justify-center gap-5">
  <img src="../../../../../assets/images/ai-initiate-assistant.gif" />
  <div class="flex flex-col items-center justify-center gap-4">
    <span>Upload in progress</span>
    <span>Please keep this window open until your upload is complete</span>
  </div>
</div>

<ng-container *ngIf="!uploading">
  <div class="flex justify-between content-center">
    <h3 mat-dialog-title>Add expert</h3>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="flex flex-col">
    <app-loader *ngIf="loading"></app-loader>

    <form *ngIf="!loading" [formGroup]="addRespondentFormGroup" class="flex flex-col gap-4">
      <p>
        You can upload call recordings that came from either Zoom or Teams. Please ensure that transcripts are in .vtt
        format, and audio files are in .m4a format.
      </p>

      <div class="flex flex-row gap-4">
        <mat-form-field class="flex-1">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstName">
          <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field class="flex-1">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastName">
          <mat-error>Required</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Segment</mat-label>
        <mat-select formControlName="segment">
          <mat-option *ngFor="let segment of segments" [value]="segment.segmentId">
            {{ segment.segmentName }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Date of call</mat-label>
        <input matInput type="datetime-local" formControlName="dateOfCall">
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Source</mat-label>
        <input matInput formControlName="source">
        <mat-error>Required</mat-error>
        <mat-hint>e.g. internal panel, Techspert</mat-hint>
      </mat-form-field>

      <div class="file-upload-container">
        <div class="outlined-file-upload">
          <label for="m4aFile" class="file-upload-label">
            Upload call recording (.m4a)
          </label>

          <input id="m4aFile" type="file" accept=".m4a" (change)="onAudioFileChange($event.target)"
            class="file-upload-input" />

          <ng-container *ngIf="addRespondentFormGroupSubmitted">
            <div class="error-message" *ngIf="m4aFileControl.hasError('required')">
              M4A file is required.
            </div>
            <div class="error-message" *ngIf="m4aFileControl.hasError('unknownFormat')">
              Only m4a audio files are accepted.
            </div>
            <div class="error-message" *ngIf="m4aFileControl.hasError('invalidContent')">
              The file is not a valid audio file.
            </div>
            <div class="error-message" *ngIf="m4aFileControl.hasError('readError')">
              There was an error reading the file. Please try again.
            </div>
          </ng-container>
        </div>

        <div class="outlined-file-upload">
          <label for="transcriptFile" class="file-upload-label">
            Upload call transcript (.vtt)
          </label>

          <input id="transcriptFile" type="file" accept=".vtt" (change)="onTranscriptFileChange($event.target)"
            class="file-upload-input" />

          <ng-container *ngIf="addRespondentFormGroupSubmitted">
            <div class="error-message"
              *ngIf="transcriptFileControl.hasError('required') || transcriptFileControl.hasError('noFile')">
              Transcript file is required.
            </div>
            <div class="error-message"
              *ngIf="transcriptFileControl.hasError('unknownFormat') || transcriptFileControl.hasError('invalidContent') || transcriptFileControl.hasError('invalidFormat')">
              Only Microsoft Teams or Zoom transcript VTT files are accepted.
            </div>
            <div class="error-message" *ngIf="transcriptFileControl.hasError('readError')">
              There was an error reading the file. Please try again.
            </div>
          </ng-container>
        </div>
      </div>

      <mat-checkbox formControlName="compliance">
        <i [class.error]="complianceControl.invalid && complianceControl.touched">
          By uploading a third party file to the ECHO platform I am agreeing to the T&Cs of the Techspert Platform
          License Agreement
        </i>
      </mat-checkbox>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="flex gap-2">
    <button class="flex-1 primaryColor-border" mat-stroked-button mat-dialog-close>
      Close window
    </button>
    <button class="flex-1" mat-flat-button color="primary" (click)="submit()">
      Confirm
    </button>
  </mat-dialog-actions>
</ng-container>