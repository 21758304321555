import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs';
import { baseMockRespondentAnswer } from '../../mocks/data-capture.mocks';
import { respondentsMock } from '../../mocks/respondents.mocks';
import {
  IDataCaptureSheet,
  IDiscussionGuideQuestion,
  IRespondent,
  IRespondentAnswer,
  IRespondentAnswerReference,
} from '../../models/data-capture.models';
import { DataCaptureService } from '../../services/data-capture.service';
import { DeleteRespondentConfirmationComponent } from '../delete-respondent-confirmation/delete-respondent-confirmation.component';
import { ViewRespondentComponent } from '../view-respondent/view-respondent.component';

type OpenCitation = {
  respondent: IRespondent;
  citation: IRespondentAnswerReference;
};

export type ExtendedRespondentAnswer = IRespondentAnswer & {
  editedAnswer?: string;
  editing: boolean;
  loading: boolean;
};

export interface IRespondentUI extends IRespondent {
  answers: Record<string, ExtendedRespondentAnswer>;
}

@Component({
  selector: 'app-data-sheet',
  templateUrl: './data-sheet.component.html',
  styleUrls: ['./data-sheet.component.scss'],
})
export class DataSheetComponent implements OnInit, OnChanges {
  @Input() dataCaptureSheetId: string;
  @Input() sheet: IDiscussionGuideQuestion[] = [];
  @Input() sheetHeaders: IDataCaptureSheet['additional_export_headers'];
  @Input() refreshDataTrigger = false;
  @Input() answerClickThrough = false;
  @Input() respondentClickThrough = false;

  @Output() openCitation = new EventEmitter<OpenCitation>();

  respondents: IRespondentUI[] = [];

  groupedThemes: {
    theme: string;
    questions: IDiscussionGuideQuestion[];
  }[];

  refreshedDataTime: number;

  loading = true;

  constructor(
    private dataCaptureService: DataCaptureService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.groupedThemes = this.groupQuestionsByTheme();

    if (this.dataCaptureSheetId) {
      this.refreshData();
    } else {
      this.respondents = respondentsMock.map((respondent) => ({
        ...respondent,
        answers: Object.entries(respondent.answers).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: {
              ...v,
              editing: false,
              loading: false,
            },
          }),
          {}
        ),
      }));
      this.loading = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataCaptureSheetId && !changes.dataCaptureSheetId.firstChange) {
      const { currentValue, previousValue } = changes.dataCaptureSheetId;
      if (currentValue !== previousValue) {
        this.refreshData();
      }
    }

    if (
      changes.sheet &&
      JSON.stringify(changes.sheet.currentValue) !==
        JSON.stringify(changes.sheet.previousValue)
    ) {
      this.groupedThemes = this.groupQuestionsByTheme();

      if (!this.dataCaptureSheetId) {
        this.respondents = this.mapMockRespondents();
      }
    }
  }

  refreshData() {
    this.loading = true;

    this.dataCaptureService
      .getRespodentsByDataCaptureSheetId(this.dataCaptureSheetId)
      .subscribe((respondents) => {
        this.respondents = respondents.map((respondent) => ({
          ...respondent,
          answers: Object.entries(respondent.answers).reduce(
            (acc, [k, v]) => ({
              ...acc,
              [k]: {
                ...v,
                editing: false,
                loading: false,
              },
            }),
            {}
          ),
        }));
        this.refreshedDataTime = Date.now();
        this.loading = false;
      });
  }

  copy(answer: IRespondentAnswer) {
    navigator.clipboard.writeText(answer.answer);
    this.toastr.success('Answer copied to clipboard');
  }

  viewRespondent(respondent: IRespondent) {
    if (!this.respondentClickThrough) {
      return;
    }

    interface IRespondentData {
      respondentId: string;
      respondents: IRespondentUI[];
      groupedThemes: DataSheetComponent['groupedThemes'];
    }

    interface IRespondentResponse extends Partial<IRespondentAnswer> {
      respondent_id: string;
      question_id: string;
    }

    this.dialog
      .open<ViewRespondentComponent, IRespondentData, IRespondentResponse[]>(
        ViewRespondentComponent,
        {
          width: '1100px',
          height: '750px',
          autoFocus: false,
          data: {
            respondentId: respondent.respondentId,
            respondents: this.respondents,
            groupedThemes: this.groupedThemes,
          },
        }
      )
      .afterClosed()
      .pipe(
        filter((res) => res && !!res.length),
        tap(() => (this.loading = true)),
        switchMap((answers) =>
          this.dataCaptureService.updateRespondentAnswer(answers)
        ),
        tap((answers) => {
          this.respondents = this.respondents.map((r) => {
            const updatedAnswers = answers.filter(
              (a) => a.respondent_id === r.respondentId
            );

            if (!updatedAnswers.length) {
              return r;
            }

            const updatedAnswersMap = updatedAnswers.reduce<
              Record<string, ExtendedRespondentAnswer>
            >(
              (acc, curr) => ({
                ...acc,
                [curr.question_id]: { ...curr, editing: false, loading: false },
              }),
              {}
            );

            return {
              ...r,
              answers: { ...r.answers, ...updatedAnswersMap },
            };
          });
        }),
        catchError(() => {
          this.toastr.error('Please try again', 'Error updating answers');
          return [];
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  viewCitation(respondent: IRespondent, citation: IRespondentAnswerReference) {
    this.openCitation.emit({ respondent, citation });
  }

  edit(answer: ExtendedRespondentAnswer) {
    if (answer.loading) {
      return;
    }

    answer.editedAnswer = answer.answer;
    answer.editing = true;
  }

  cancelEdit(answer: ExtendedRespondentAnswer) {
    if (answer.loading) {
      return;
    }

    answer.editedAnswer = '';
    answer.editing = false;
  }

  saveAnswer(answer: ExtendedRespondentAnswer) {
    answer.loading = true;
    answer.answer = answer.editedAnswer;
    answer.editing = false;

    this.dataCaptureService
      .updateRespondentAnswer([
        {
          respondent_id: answer.respondent_id,
          question_id: answer.question_id,
          answer: answer.editedAnswer,
        },
      ])
      .pipe(
        catchError((err) => {
          answer.editing = true;
          this.toastr.error('Please try again', 'Error saving answer');
          return err;
        }),
        finalize(() => (answer.loading = false))
      )
      .subscribe();
  }

  deleteRespondent(respondent: IRespondent) {
    this.dialog
      .open<DeleteRespondentConfirmationComponent, IRespondent, boolean>(
        DeleteRespondentConfirmationComponent,
        {
          width: '500px',
          autoFocus: false,
          data: respondent,
        }
      )
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        switchMap(() =>
          this.dataCaptureService.deleteExternalConnection(
            respondent.respondentId
          )
        ),
        tap(() => {
          this.respondents = this.respondents.filter(
            (r) => r.respondentId !== respondent.respondentId
          );
        }),
        catchError((err) => {
          this.toastr.error('Please try again', 'Error deleting respondent');
          return err;
        })
      )
      .subscribe();
  }

  private groupQuestionsByTheme() {
    if (!this.sheet) {
      return [];
    }

    const themes = this.sheet.map((question) => question.theme);

    return [...new Set(themes)].map((theme) => ({
      theme,
      questions: this.sheet.filter((question) => question.theme === theme),
    }));
  }

  private mapMockRespondents() {
    return respondentsMock.map((respondent) => ({
      ...respondent,
      answers: this.sheet.reduce<Record<string, ExtendedRespondentAnswer>>(
        (acc, question, i) => ({
          ...acc,
          [question.question_id]: {
            ...baseMockRespondentAnswer,
            question_id: question.question_id,
            answer: this.getRandomLoremIpsum(i),
            editing: false,
            loading: false,
          },
        }),
        {}
      ),
    }));
  }

  private getRandomLoremIpsum(index: number) {
    const loremIpsum = [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    ];

    return loremIpsum[index % loremIpsum.length];
  }
}
