import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalTranscriptValidationService {
  validate(transcript: File): Observable<ValidationErrors | null> {
    if (!transcript) {
      return new Observable((observer) => {
        observer.next({ noFile: true });
        observer.complete();
      });
    }

    return new Observable((observer) => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const content = fileReader.result as string;

        if (!content.startsWith('WEBVTT')) {
          observer.next({ invalidFormat: true });
          observer.complete();
          return;
        }

        const isTeams = content.includes('<v ');
        const isZoom =
          /\d+\r?\n\d{2}:\d{2}:\d{2}\.\d{3} --> \d{2}:\d{2}:\d{2}\.\d{3}\r?\n[^:\n]+:\s*/.test(
            content
          );

        if (!isTeams && !isZoom) {
          observer.next({ unknownFormat: true });
          observer.complete();
          return;
        }

        const processedTranscript = isTeams
          ? this.preProcessTeams(content)
          : this.preProcessZoom(content);

        if (
          !processedTranscript.length ||
          processedTranscript.some((t) => !t.content.trim())
        ) {
          observer.next({ invalidContent: true });
          observer.complete();
          return;
        }

        observer.next(null);
        observer.complete();
      };

      fileReader.onerror = () => {
        observer.next({ readError: true });
        observer.complete();
      };

      fileReader.readAsText(transcript);
    });
  }

  private preProcessTeams(content: string) {
    const matches = content.matchAll(/<v ([^>]+)>(.+?)<\/v>/g);

    return [...matches].map(([, speaker, text]) => ({
      speaker: speaker.trim(),
      content: text.trim(),
    }));
  }

  private preProcessZoom(content: string) {
    const matches = content.matchAll(
      /\d+\r?\n\d{2}:\d{2}:\d{2}\.\d{3} --> \d{2}:\d{2}:\d{2}\.\d{3}\r?\n([^:\n]+):\s*(.+(?:\r?\n(?!\d+\r?\n).*)*)/g
    );

    return [...matches].map(([, speaker, text]) => ({
      speaker: speaker.trim(),
      content: text.trim(),
    }));
  }
}
