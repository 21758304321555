<div class="assistant-page flex justify-start content-center" *ngIf="!unauthorised">
  <ng-component *ngIf="!failedMessage" [ngTemplateOutlet]="query"></ng-component>

  <div class="central-container flex grow">
    <h2 class="failed-message" *ngIf="failedMessage">
      {{failedMessage}}
    </h2>

    <form *ngIf="!loading && !failed && !failedMessage" [formGroup]="messageForm"
      class="thread-container flex flex-col justify-between content-center gap-6" (submit)="sendMessage()">

      <div *ngIf="initialisedState && !loading">
        <ng-component [ngTemplateOutlet]="initial"></ng-component>
      </div>

      <div *ngIf="!initialisedState" class="thread-content flex flex-col gap-6" #scrollContainer>
        <div class="thread" *ngFor="let message of threadMessagesInner"
          [class.assistant]="message.role === 'assistant'">
          <b>{{message.role === 'user' ? 'You' : 'AI Assistant'}}</b>
          <span *ngFor="let content of message.content" [appPromptResponse]="content.text.value" [files]="entityFiles"
            [externalFileMap]="externalFileMap" (tabChange)="tabChange($event)">
          </span>
        </div>

        <div *ngIf="polling" class="question-container flex justify-start items-center gap-2">
          <div class="thread assistant">
            <div class="typing">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
          <span>Generating your answer. This could take a moment...</span>
        </div>
      </div>

      <p *ngIf="selectedThread && selectedThread.archived" class="question-container">
        This chat is now archived. Please start a new chat.
      </p>

      <div *ngIf="!selectedThread || (selectedThread && !selectedThread.archived)"
        class="question-container flex flex-col justify-start content-center gap-4">
        <div class="flex flex-col justify-start content-center gap-2">
          <span class="get-started">Example prompts</span>

          <div class="prompt-container flex flex-row justify-start content-center gap-2">
            <div class="prompt flex justify-between items-center enabled-prompt" *ngFor="let prompt of availablePrompts"
              (click)="selectPrompt(prompt.value)">
              <span>{{prompt.displayText}}</span>
              <mat-icon>copy</mat-icon>
            </div>
          </div>
        </div>

        <div class="input flex justify-start items-center gap-2">
          <mat-form-field class="flex grow">
            <textarea matInput cdkTextareaAutosize formControlName="message" (keyup.enter)="$event.preventDefault()"
              (keydown)="sendMessageOnEnter($event)"></textarea>
          </mat-form-field>
          <button mat-flat-button color="primary" type="submit"
            [disabled]="polling || selectedThread && selectedThread.archived || messageForm.invalid">
            <mat-icon [class.spin]="polling">{{polling ? 'autorenew' : 'send'}}</mat-icon>
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="loading" class="thread-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div *ngIf="!failedMessage" class="mat-group-assistant-style">
    <mat-tab-group [(selectedIndex)]="tabIndex">
      <mat-tab label="File library">
        <app-assistant-files [files]="transcriptEntityFiles" [mappedFiles]="mappedEntityFiles"
          (viewFileEvent)="viewFile($event)"></app-assistant-files>
      </mat-tab>
      <mat-tab label="Transcript display">
        <app-assistant-playback></app-assistant-playback>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div *ngIf="unauthorised" class="assistant-page flex flex-col justify-center content-center">
  <app-assistant-unauthorised></app-assistant-unauthorised>
</div>

<ng-template #initial>
  <div class="flex flex-col gap-8 init-container" *ngIf="resource === 'opportunity'">
    <app-sparkle fillColour="#1f3f72" width="36px" height="36px"></app-sparkle>

    <div class="flex flex-col gap-2">
      <span class="title">Welcome {{loggedInName | titlecase}}</span>
      <span>How can I help you today?</span>
    </div>

    <span class="question-body">
      You’re currently asking questions of <b>all completed calls for this project</b>. Create a custom segment by
      selecting your experts here:
    </span>

    <div class="flex flex-row gap-2 align-middle">
      <button mat-flat-button color="primary" type="button" (click)="makeSelection('segment')">
        Select segments
      </button>
      <button mat-flat-button color="primary" type="button" (click)="makeSelection('expert')">
        Select experts
      </button>
    </div>
  </div>

  <div class="flex flex-col gap-8 init-container" *ngIf="resource !== 'opportunity'">
    <span class="question-body">
      Your <b>AI Assistant</b> has been configured for your custom audience:
    </span>

    <ul class="flex flex-col gap-4">
      <li *ngFor="let resource of resourceIds">
        <span>
          {{entityMap[resource].name}} ({{entityMap[resource].callCount}} {{entityMap[resource].callCount > 1 ? 'calls'
          : 'call'}})
        </span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #query>
  <div class="assistant-sidebar flex flex-col justify-start content-center gap-4">

    <span class="get-started">Get started</span>

    <button mat-stroked-button color="primary" (click)="initChat()"
      [matTooltip]="initialisedState && resource === 'opportunity' ? 'New chat already loaded' : ''">
      Start new chat
    </button>

    <span class="get-started">Recent chats</span>

    <span *ngIf="loadingThreads" class="group">We're loading recent chats</span>

    <div *ngIf="!loadingThreads" class="flex flex-col gap-2 justify-start content-center">
      <div *ngFor="let thread of threads" class="flex flex-col gap-1">
        <span class="group">{{thread.group}}</span>
        <div *ngFor="let threadInner of thread.threads" [class.selected]="threadInner.assistantThreadId === threadId"
          (click)="selectThread(threadInner)" class="thread flex justify-between items-center">
          <span [matTooltip]="threadInner.label" matTooltipPosition="right">{{threadInner.label}}</span>

          <div class="flex flex-row gap-1 justify-start content-center">
            <mat-icon *ngIf="threadInner.archived" class="archived"
              matTooltip="This thread is archived and read only">lock</mat-icon>
            <mat-icon class="delete" (click)="$event.stopPropagation(); deleteThread(threadInner);"
              matTooltip="Delete thread" matTooltipPosition="right">delete</mat-icon>
          </div>
        </div>
      </div>

      <span class="group" *ngIf="!threads.length">No prior chats</span>
    </div>

    <p>
      The answers generated use AI and may contain inaccuracies. The onus is on you to
      conduct your own due diligence to ensure the accuracy and validity of the data generated. Please consult
      <a href="https://techspert.com/ai-compliance-statement" target="_blank">Techspert's AI Compliance statement</a>
      for further details.
    </p>
  </div>
</ng-template>