import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StandardiseTranscriptService {
  standardise(transcript: File): Observable<File> {
    return new Observable((observer) => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const content = fileReader.result as string;

        const isTeams = content.includes('<v ');
        const isZoom =
          /\d+\r?\n\d{2}:\d{2}:\d{2}\.\d{3} --> \d{2}:\d{2}:\d{2}\.\d{3}/.test(
            content
          );

        if (!isTeams && !isZoom) {
          observer.error('Invalid transcript format');
          return;
        }

        const normalisedContent = isTeams
          ? this.convertTeamsToZoom(content)
          : content;

        const normalisedFile = new File([normalisedContent], transcript.name, {
          type: 'text/vtt',
        });

        observer.next(normalisedFile);
        observer.complete();
      };

      fileReader.onerror = () => {
        observer.error('Error reading file');
      };

      fileReader.readAsText(transcript);
    });
  }

  private convertTeamsToZoom(content: string): string {
    const lines = content.split(/\r?\n/);
    const output = ['WEBVTT', ''];

    let counter = 1;
    let timestamp = '';
    let speaker = '';
    let dialogue = '';

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();

      if (line.includes('-->')) {
        timestamp = line;
      } else if (line.startsWith('<v ') && line.endsWith('</v>')) {
        const match = line.match(/<v ([^>]+)>(.+?)<\/v>/);
        if (match) {
          speaker = match[1].trim();
          dialogue = match[2].trim();
        }
      }

      if (timestamp && speaker && dialogue) {
        output.push(`${counter}`);
        output.push(timestamp);
        output.push(`${speaker}: ${dialogue}`);
        output.push('');

        counter++;
        timestamp = '';
        speaker = '';
        dialogue = '';
      }
    }

    return output.join('\r\n');
  }
}
