import { FileType } from '@techspert-io/files';

export interface IDiscussionGuideQuestion {
  question_id: string;
  question: string;
  theme: string;
  label: string;
}

export interface IDataCaptureSheetBase {
  data_capture_sheet_id: string;

  name: string;

  opportunity_id: string; // Index

  opportunity_segment_ids: string[];

  discussion_guide: IDiscussionGuideQuestion[];

  answer_format: 'summarised' | 'verbatim';

  additional_export_headers: {
    expert_name: boolean;
    expert_bio: boolean;

    geography: boolean;
    profile_type: boolean;

    date_and_time_of_call: boolean;

    /* Not required for v1 */
    interviewer_name: boolean;

    /* Not required for v1 */
    notetaker_name: boolean;
  };
}

export interface IDataCaptureSheetAccessLogs {
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export enum RespondentStatus {
  ProcessingRecording = 'processingRecording',
  Transcription = 'transcription',
  GeneratingResponse = 'generatingResponse',
  Complete = 'complete',
}

export interface IDataCaptureSheet
  extends IDataCaptureSheetBase,
    IDataCaptureSheetAccessLogs {}

export interface IRespondentAnswerReference {
  citation: string;
  timestampStart: number;
  timestampEnd: number;
}

export interface IRespondentAnswerBase {
  /* ExpertId or external respondent id */
  respondent_id: string;

  question_id: string;

  answer: string;

  /* Could be a transcript chunk ID in the future */
  citation: IRespondentAnswerReference[];

  answer_format: IDataCaptureSheet['answer_format'];
}

export interface IRespondentAnswerAccessLogs {
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export interface IRespondentFile {
  fileName: string;
  fileExtension: string;
  fileKey: string;
  type: FileType;
  fileId?: string;
}

export interface IRespondentAnswer
  extends IRespondentAnswerBase,
    IRespondentAnswerAccessLogs {}

export enum RespondentSource {
  Techspert = 'techspert',
  Other = 'other',
}

export interface IRespondent {
  /** callActionId or external respondent id */
  respondentId: string;

  firstName: string;
  lastName: string;

  geography: string;
  segment: string;
  opportunitySegmentId: string;

  /** ISO format */
  callDate: string;

  bio: string;

  type: RespondentSource;

  /** If type is techspert then source is expertId */
  source: string;

  /** [key: questionId] IRespondentAnswer */
  answers: Record<string, IRespondentAnswer>;

  files: IRespondentFile[];

  transcriptSummary?: IRespondentFile;
  processedTranscript?: IRespondentFile;
  audio?: IRespondentFile;
}

export interface IDataCaptureSegment {
  segmentId: string;
  segmentName: string;
  occupied: boolean;
}

export interface IExternalConnectionUpload {
  external_connection_id: string;
  opportunity_id: string;
  opportunity_segment_id: string;
  first_name: string;
  last_name: string;
  call_datetime: string;
  source: string;
  compliance_accepted: boolean;

  audioFileKey: string;
  transcriptFileKey: string;
}
