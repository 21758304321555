import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AssistantFilesService,
  AssistantQuotesService,
  TranscriptChunkingService,
} from '@techspert-io/insight-assistant';
import { combineLatest, of } from 'rxjs';
import {
  IDiscussionGuideQuestion,
  IRespondent,
  IRespondentAnswer,
  IRespondentAnswerReference,
  IRespondentFile,
  RespondentSource,
} from '../../models/data-capture.models';

type GroupedTheme = {
  theme: string;
  questions: IDiscussionGuideQuestion[];
};

export interface IRespodentDialogData {
  respondentId: string;
  respondents: IRespondent[];
  groupedThemes: GroupedTheme[];
}

interface ITranscriptChunk {
  start: string;
  end: string;
  startNum: number;
  endNum: number;
  speaker: string;
  content: string;
  highlighted: boolean;
  savedQuoteId?: string;
  loading?: boolean;
}

@Component({
  selector: 'app-view-respondent',
  templateUrl: './view-respondent.component.html',
  styleUrls: ['./view-respondent.component.scss'],
})
export class ViewRespondentComponent implements OnInit {
  loading = true;

  selectedRespondent: IRespondent;

  groupedThemes: GroupedTheme[];

  audioStartTime = 0;
  transcriptFile: IRespondentFile;
  audioData: string;
  autoScroll = true;
  transcriptChunks: ITranscriptChunk[] = [];

  answerUpdates: Record<string, string> = {};

  get respondentId() {
    if (!this.selectedRespondent) {
      return;
    }

    return this.selectedRespondent.type === RespondentSource.Techspert
      ? this.selectedRespondent.source
      : this.selectedRespondent.respondentId;
  }

  constructor(
    private assistantFilesService: AssistantFilesService,
    private assistantQuotesService: AssistantQuotesService,
    private transcriptChunkingService: TranscriptChunkingService,
    private dialogRef: MatDialogRef<ViewRespondentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IRespodentDialogData
  ) {}

  ngOnInit() {
    this.loadResources(this.data.respondentId);
    this.groupedThemes = this.data.groupedThemes;
  }

  navigateBack() {
    const currentIndex = this.data.respondents.findIndex(
      (r) => r.respondentId === this.selectedRespondent.respondentId
    );

    const previousIndex =
      currentIndex === 0 ? this.data.respondents.length - 1 : currentIndex - 1;

    this.loadResources(this.data.respondents[previousIndex].respondentId);
  }

  navigateForward() {
    const currentIndex = this.data.respondents.findIndex(
      (r) => r.respondentId === this.selectedRespondent.respondentId
    );

    const nextIndex =
      currentIndex === this.data.respondents.length - 1 ? 0 : currentIndex + 1;

    this.loadResources(this.data.respondents[nextIndex].respondentId);
  }

  navigateCitation(citation: IRespondentAnswerReference) {
    this.audioStartTime = citation.timestampStart
      ? citation.timestampStart / 1000
      : 0;

    this.updateAndScroll(this.audioStartTime);
  }

  updateTime(event: Event) {
    const target = event.target as HTMLAudioElement;

    this.updateAndScroll(target.currentTime);
  }

  updateQuote(chunk: ITranscriptChunk) {
    if (chunk.loading) {
      return;
    }

    chunk.loading = true;

    if (chunk.savedQuoteId) {
      this.assistantQuotesService
        .deleteQuote(chunk.savedQuoteId)
        .subscribe(() => {
          chunk.savedQuoteId = null;
          chunk.loading = false;
        });
    } else {
      this.assistantQuotesService
        .saveQuote({
          transcriptFileId:
            this.transcriptFile.fileId || this.selectedRespondent.respondentId,

          expertId:
            this.selectedRespondent.type === RespondentSource.Techspert
              ? this.selectedRespondent.source
              : this.selectedRespondent.respondentId,
          startTime: chunk.startNum,
          endTime: chunk.endNum,
          quote: chunk.content,
        })
        .subscribe((quote) => {
          chunk.savedQuoteId = quote.savedQuoteId;
          chunk.loading = false;
        });
    }
  }

  confirm() {
    const changedAnswers = Object.entries(this.answerUpdates).map(
      ([key, answer]) => {
        const [respondentId, questionId] = key.split('_');
        return {
          respondent_id: respondentId,
          question_id: questionId,
          answer,
        };
      }
    );

    this.dialogRef.close(changedAnswers);
  }

  answerModification(respondentAnswer: IRespondentAnswer, answer: string) {
    this.answerUpdates[
      `${respondentAnswer.respondent_id}_${respondentAnswer.question_id}`
    ] = answer;
  }

  private loadResources(respondentId: string) {
    this.loading = true;

    const respondent = this.data.respondents.find(
      (r) => r.respondentId === respondentId
    );
    this.selectedRespondent = { ...respondent };

    if (
      !this.selectedRespondent.audio ||
      !this.selectedRespondent.processedTranscript
    ) {
      this.transcriptFile = undefined;
      this.audioData = undefined;
      this.loading = false;
      return;
    }

    combineLatest([
      this.assistantFilesService.getAssistantTranscriptFileContents(
        this.selectedRespondent.processedTranscript
      ),
      this.selectedRespondent.audio
        ? this.assistantFilesService.getAssistantAudioFileContents(
            this.selectedRespondent.audio
          )
        : of(undefined),
      this.assistantQuotesService.getQuotesByTranscriptFileId(
        this.selectedRespondent.processedTranscript?.fileId || respondentId
      ),
    ]).subscribe(([content, audio, quotes]) => {
      this.transcriptChunks = this.transcriptChunkingService.createChunks(
        content,
        quotes
      );

      this.transcriptFile = this.selectedRespondent.processedTranscript;

      if (audio) {
        this.audioData = URL.createObjectURL(audio);
      }

      this.audioStartTime = 0;

      this.updateAndScroll(this.audioStartTime);

      this.loading = false;
    });
  }

  private updateAndScroll(time: number) {
    const currentHighlightedPosition = this.transcriptChunks.findIndex(
      (chunk) => chunk.highlighted
    );

    const newHighlightedPosition = this.transcriptChunks.findIndex(
      (chunk) => chunk.startNum <= time && chunk.endNum >= time
    );

    if (currentHighlightedPosition !== newHighlightedPosition) {
      this.transcriptChunks = this.transcriptChunks.map((chunk) => ({
        ...chunk,
        highlighted: chunk.startNum <= time && chunk.endNum >= time,
      }));
    }

    if (this.autoScroll) {
      setTimeout(() => {
        const element = document.querySelector('.highlight');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }
      });
    }

    if (!time) {
      const element = document.querySelector('.transcript-content');
      if (element) {
        element.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }
}
