import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { catchError, combineLatest, finalize, map, switchMap, tap } from 'rxjs';
import { IDataCaptureSegment } from '../../models/data-capture.models';
import { DataCaptureService } from '../../services/data-capture.service';
import { ExternalAudioValidationService } from '../../services/external-audio-validation.service';
import { ExternalTranscriptValidationService } from '../../services/external-transcript-validation.service';
import { StandardiseTranscriptService } from '../../services/standardise-transcript.service';

@Component({
  selector: 'app-add-respondent',
  templateUrl: './add-respondent.component.html',
  styleUrls: ['./add-respondent.component.scss'],
})
export class AddRespondentComponent implements OnInit {
  addRespondentFormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    segment: new FormControl('', Validators.required),
    dateOfCall: new FormControl('', Validators.required),
    source: new FormControl('', Validators.required),
    m4aFile: new FormControl<File>(null, {
      validators: [Validators.required],
      asyncValidators: this.m4aValidator.bind(this),
    }),
    transcriptFile: new FormControl<File>(null, {
      validators: [Validators.required],
      asyncValidators: this.transcriptValidator.bind(this),
    }),
    compliance: new FormControl(false, Validators.requiredTrue),
  });

  segments: IDataCaptureSegment[] = [];

  loading = true;
  uploading = false;

  addRespondentFormGroupSubmitted = false;

  get complianceControl() {
    return this.addRespondentFormGroup.controls.compliance;
  }

  get m4aFileControl() {
    return this.addRespondentFormGroup.controls.m4aFile;
  }

  get transcriptFileControl() {
    return this.addRespondentFormGroup.controls.transcriptFile;
  }

  constructor(
    private dataCaptureService: DataCaptureService,
    private externalTranscriptValidationService: ExternalTranscriptValidationService,
    private externalAudioValidationService: ExternalAudioValidationService,
    private standardiseTranscriptService: StandardiseTranscriptService,
    private dialogRef: MatDialogRef<AddRespondentComponent>,
    private toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) private opportunityId: string
  ) {}

  ngOnInit() {
    this.dataCaptureService
      .getAvailableSegments(this.opportunityId)
      .subscribe((res) => {
        this.segments = res;
        this.loading = false;
      });
  }

  onAudioFileChange(target: HTMLInputElement) {
    const file = Array.from(target?.files || []).find(Boolean);

    if (file) {
      const validMimeTypes = ['audio/mp4', 'audio/x-m4a'];
      const validExtension = file.name.toLowerCase().endsWith('.m4a');

      if (!validMimeTypes.includes(file.type) || !validExtension) {
        this.m4aFileControl.setErrors({ unknownFormat: true });
        return;
      }

      this.addRespondentFormGroup.patchValue({ m4aFile: file });
      this.m4aFileControl.updateValueAndValidity();
    }
  }

  onTranscriptFileChange(target: HTMLInputElement) {
    const file = Array.from(target?.files || []).find(Boolean);

    if (file) {
      if (file.type !== 'text/vtt') {
        this.transcriptFileControl.setErrors({ unknownFormat: true });
        return;
      }

      this.addRespondentFormGroup.patchValue({ transcriptFile: file });
      this.transcriptFileControl.updateValueAndValidity();
    }
  }

  submit() {
    this.addRespondentFormGroupSubmitted = true;

    if (!this.addRespondentFormGroup.valid) {
      this.addRespondentFormGroup.markAllAsTouched();
      return;
    }

    this.uploading = true;

    const m4aFile = this.m4aFileControl.value;
    const transcriptFile = this.transcriptFileControl.value;

    const respondentUid = this.generateUniqueId();

    this.standardiseTranscriptService
      .standardise(transcriptFile)
      .pipe(
        switchMap((sTranscript) =>
          this.dataCaptureService
            .uploadExternalConnectionFile({
              externalRespondentId: respondentUid,
              opportunitySegmentId: this.addRespondentFormGroup.value.segment,
            })
            .pipe(
              switchMap((res) =>
                combineLatest([
                  this.dataCaptureService
                    .uploadFile(m4aFile, res.audio.uploadUrl)
                    .pipe(map(() => res.audio.fileKey)),
                  this.dataCaptureService
                    .uploadFile(sTranscript, res.transcript.uploadUrl)
                    .pipe(map(() => res.transcript.fileKey)),
                ])
              ),
              switchMap(([audio, transcript]) =>
                this.dataCaptureService.createExternalConnection({
                  external_connection_id: respondentUid,
                  opportunity_id: this.opportunityId,
                  opportunity_segment_id:
                    this.addRespondentFormGroup.value.segment,
                  first_name: this.addRespondentFormGroup.value.firstName,
                  last_name: this.addRespondentFormGroup.value.lastName,
                  call_datetime: this.addRespondentFormGroup.value.dateOfCall,
                  source: this.addRespondentFormGroup.value.source,
                  compliance_accepted: true,
                  audioFileKey: audio,
                  transcriptFileKey: transcript,
                })
              )
            )
        ),
        tap(() => {
          this.dialogRef.close(true);
        }),
        catchError(() => {
          this.toastService.error(
            'Please try again or contact your project manager if this persists',
            'Error uploading expert'
          );
          return [];
        }),
        finalize(() => (this.uploading = false))
      )
      .subscribe();
  }

  private generateUniqueId() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  private transcriptValidator(control: FormControl<File>) {
    return this.externalTranscriptValidationService.validate(control.value);
  }

  private m4aValidator(control: FormControl<File>) {
    return this.externalAudioValidationService.validate(control.value);
  }
}
