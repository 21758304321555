<div class="flex flex-row">
  <div class="data-capture-group" [class.viewing-citation]="viewingCitation">
    <div class="sheet-container" *ngIf="loading || deletingSheet">
      <div class="sheet-area">
        <app-loader></app-loader>
      </div>
    </div>

    <div class="sheet-container" *ngIf="!loading && !deletingSheet">
      <div class="sheet-area">
        <app-sheet-get-started *ngIf="!selectedSheet" [opportunityId]="opportunityId"
          (createdSheet)="createdSheet($event)" (cancel)="cancelSetup()" [step]="availableSheets.length ? 1 : 0"
          [initialSheet]="!availableSheets.length"></app-sheet-get-started>
        <app-data-sheet *ngIf="selectedSheet" [dataCaptureSheetId]="selectedSheet.data_capture_sheet_id"
          [sheet]="selectedSheet.discussion_guide" [sheetHeaders]="selectedSheet.additional_export_headers"
          [refreshDataTrigger]="true" [answerClickThrough]="true" [respondentClickThrough]="true"
          (openCitation)="openCitation($event)"></app-data-sheet>
      </div>
      <div class="sheet-selection flex items-center">
        <ng-container *ngIf="availableSheets.length">
          <div class="sheet"
            [class.selected]="selectedSheet && selectedSheet.data_capture_sheet_id === sheet.data_capture_sheet_id"
            *ngFor="let sheet of availableSheets" (click)="selectSheet(sheet)">
            <b class="sheet-name" [matTooltip]="sheet.name">{{ sheet.name }}</b>
            <mat-icon [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">more_vert</mat-icon>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(sheet)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="downloadCsv(sheet)">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
              <button mat-menu-item (click)="deleteSheet(sheet)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
          <div class="sheet add" (click)="newSheet()">
            <b>+</b>
          </div>
        </ng-container>

        <ng-container *ngIf="!availableSheets.length">
          <div class="sheet add">
            <b>Get started</b>
          </div>
        </ng-container>

        <div class="button-action-container flex gap-2 items-center">
          <button mat-stroked-button color="primary" (click)="addRespondent()" [disabled]="loading">
            <mat-icon>person_add</mat-icon>
            Add expert
          </button>
          <button mat-flat-button color="primary" (click)="downloadCsv()" [disabled]="loading || downloadingCsv">
            <mat-icon class="material-symbols-outlined">download</mat-icon>
            Download
          </button>
        </div>
      </div>
    </div>

    <span class="disclaimer">Responses are generated using AI - you are responsible for verifying accuracy.</span>
  </div>

  <div class="transcript-container" *ngIf="viewingCitation">
    <div class="top-container flex flex-row justify-between items-center">
      <h4>Reference</h4>

      <button mat-icon-button (click)="closeCitation()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <div *ngIf="loadingCitation" class="inner-container">
      <app-loader></app-loader>
    </div>

    <div *ngIf="!loadingCitation" class="inner-container">
      <div class="inner-transcript-container flex flex-col flex-grow">
        <div class="transcript-content" *ngIf="transcriptFile && transcriptChunks.length">
          <div class="chunk" *ngFor="let chunk of transcriptChunks" [class.highlight]="chunk.highlighted">
            <div class="flex flex-row justify-between items-center">
              <span>{{chunk.start}} --> {{chunk.end}}</span>

              <div class="flex flex-row items-center content-center gap-1">
                <mat-icon (click)="updateQuote(chunk)" [matTooltip]="chunk.savedQuoteId ? 'Unfavorite' : 'Favorite'">
                  {{chunk.loading ? 'autorenew' : chunk.savedQuoteId ? 'star' : 'star_outlined'}}
                </mat-icon>
              </div>
            </div>
            <span><b>{{chunk.speaker}}</b>: {{chunk.content}}</span>
          </div>
        </div>
      </div>

      <div class="inner-transcript-container" style="min-height: 125px">
        <div class="flex items-center justify-between">
          <b>Audio</b>
          <div *ngIf="transcriptFile && transcriptChunks.length" class="flex gap-2 items-center">
            <span>Auto scroll</span>
            <mat-slide-toggle [(ngModel)]="autoScroll"></mat-slide-toggle>
          </div>
        </div>
        <span *ngIf="!audioData && !transcriptFile">Please select a file to play</span>
        <span *ngIf="!audioData && transcriptFile">Audio file is unavailable</span>

        <audio *ngIf="audioData" controls [audioTime]="audioStartTime" (timeupdate)="updateTime($event)">
          <source [src]="audioData" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  </div>
</div>