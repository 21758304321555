import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IRespondent } from '../../models/data-capture.models';

@Component({
  selector: 'app-delete-respondent-confirmation',
  template: `<div class="flex justify-between content-center">
      <h3 mat-dialog-title>Remove expert from project</h3>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content class="flex flex-col gap-2">
      <p>
        You are about to remove &lt;{{ respondent.firstName }}
        {{ respondent.lastName }}&gt; from this project. This action will also
        delete all of their uploaded files from Techspert's platform.
      </p>

      <p>Are you sure you want to continue?</p>

      <form [formGroup]="deleteAnswersForm">
        <mat-checkbox formControlName="deleteAnswers">
          <i
            [class.error]="
              deleteAnswersControl.invalid && deleteAnswersControl.touched
            "
          >
            I understand and wish to proceed.
          </i>
        </mat-checkbox>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions class="flex gap-2">
      <button
        type="button"
        mat-stroked-button
        mat-dialog-close
        color="primary"
        class="flex-1 primaryColor-border"
      >
        Close window
      </button>
      <button
        class="flex-1"
        type="submit"
        mat-flat-button
        color="primary"
        (click)="confirmDeletion()"
      >
        Confirm
      </button>
    </mat-dialog-actions>`,
  styles: `.error { color: red }`,
})
export class DeleteRespondentConfirmationComponent {
  deleteAnswersForm = new FormGroup({
    deleteAnswers: new FormControl(false, [Validators.requiredTrue]),
  });

  get deleteAnswersControl() {
    return this.deleteAnswersForm.controls.deleteAnswers;
  }

  constructor(
    private dialogRef: MatDialogRef<DeleteRespondentConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public respondent: IRespondent
  ) {}

  confirmDeletion() {
    if (this.deleteAnswersForm.invalid) {
      this.deleteAnswersForm.markAllAsTouched();
      return;
    }

    this.dialogRef.close(true);
  }
}
